import React from 'react'
import { Page, Text, View, Image, Button } from '@/components'
import { AppStatus } from '@/redux'
import { onUpdate } from '@codeleap/common'
import { APIClient } from '@/services'
import { AppImages, Settings, navigation, useAppI18N } from '@/config'
import { createStyles } from '@codeleap/styles'

export default () => {
  const { isLoggedIn } = APIClient.Session.useSession()

  onUpdate(() => {
    AppStatus.setReady(isLoggedIn)
  }, [isLoggedIn])

  const {t} = useAppI18N()

  return (
    <Page pageTitle='Home' centerContent={false}>
      <View style={[styles.wrapper, { breakpoints: { tabletSmall: ['column'] } }]}>
        <View style={['column', 'flex', 'center', 'alignStart', 'padding:4', 'gap:4', { breakpoints: { tabletSmall: ['alignCenter', 'textCenter'] } }]}>
          <View style={['column', 'center', 'alignStart', { breakpoints: { tabletSmall: ['alignCenter', 'textCenter'] } }]}>
            <Text style={['hx']} text='Welcome to' />
            <Text style={['hx']} text={`project`} />
            <Text style={['hx', 'color:primary3']} text={'NextJS ' + Settings.AppName} />
          </View>

          <Text style={['p1']} text={t('welcome')} />

          <Button
            style={['primary:outline', 'marginTop:2', 'large']}
            onPress={() => navigation.navigate('Playground')}
            text='Playground'
            debugName='Home button'
            rightIcon='chevron-right'
          />
        </View>

        <Image alt='welcome image' source={AppImages.HomeImage} style={styles.image} objectFit='cover' />
      </View>
    </Page>
  )
}

const styles = createStyles(theme => ({
  wrapper: {
    ...theme.presets.fullWidth,
    minHeight: '90vh',
  },
  image: {
    width: '45%',

    [theme.media.down('tabletSmall')]: {
      width: '100%',
    },
  },
}))
